import React from 'react'
import './about.css'
import Aimg from '../assets/about.jpeg'
import { NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'
import { fadeIn, textVariant } from '../utlis/motion'
const Aboutdata = () => {
  return (
    <>
      <div className="about">
        <motion.h1 initial={{ x: -1000 }}
          animate={{ x: 0 }}
          transition={{
            duration: 0.2,
            delay: 1
          }} className="about_title">About me </motion.h1>
        <div className="about_content">
          <div className="left">
            <motion.h1 initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, type: "spring" }}
              viewport={{ once: true }}>Who Am I ?</motion.h1>
            <motion.p initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, delay: 1, type: "spring" }}
              viewport={{ once: true }}>Hello! I'm a student currently pursuing a Bachelor of Technology (B.Tech) in Electronics and Computer Science (ECS)
              at Atharva College of Engineering. Passionate about technology and innovation, I am dedicated to expanding my knowledge and skills
              in this dynamic field.</motion.p>
            <motion.p initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, delay: 1, type: "spring" }}
              viewport={{ once: true }}>Working in this  domain for last
              5 yrs, has taught me how to solve problems. How to create something that is used by the masses and how to learn new things
            </motion.p>
            <motion.div initial={{ opacity: 0, scale: 0, }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, type: "spring" }}
              viewport={{ once: true }}>
              <NavLink to="/project" className="btn"> Project </NavLink>
            </motion.div>
          </div>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, type: "spring" }}
            viewport={{ once: true }}

            className="right">
            <img src={Aimg} alt="aimg" />
          </motion.div>
        </div>
      </div>
    </>
  )
}

export default Aboutdata
