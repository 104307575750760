import React, { Component } from 'react'
import './hero2.css'
import { motion } from 'framer-motion'
class Heroimg2 extends Component {
    render() {
        return (
            <>
                <div className="hero-img">
                    <div className="heading">
                        <motion.h1
                            initial={{ x: -1000 }}
                            animate={{ x: 0 }}
                            transition={{
                                duration: 0.2,
                                delay: 0
                            }}

                        >{this.props.heading}</motion.h1>
                        <motion.p
                            initial={{ x: -1000 }}
                            animate={{ x: 0 }}
                            transition={{
                                duration: 0.2,
                                delay: 0
                            }}>{this.props.text}</motion.p>
                    </div>
                </div>
            </>
        )
    }
}

export default Heroimg2
