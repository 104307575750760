import React from 'react'
import './card.css'
import Workcard from './Workcard'
import WorkCardData from './WorkCardData'
import { motion } from 'framer-motion'
const Work = () => {
    return (
        <>
            <div className="container">
                <motion.h1
                    initial={{ x: -1000 }}
                    animate={{ x: 0 }}
                    transition={{
                        duration: 0.2,
                        delay: 1
                    }} className="project-heading">Projects</motion.h1>
                <div className="project-container">
                    {WorkCardData.map((val, ind,) => {
                        return (
                            <motion.div initial={{ opacity: 0, scale: 0 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.6, type: "spring" }}
                                viewport={{ once: true }}>
                                <Workcard
                                    key={ind}
                                    imgsrc={val.imgsrc}
                                    title={val.title}
                                    text={val.text}
                                    view={val.view}
                                    source={val.source}
                                />
                            </motion.div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default Work
