import React from 'react'
import CountUp from "react-countup";


const Count = ({ number, title, text }) => {
	return (
		<div className='number'>
			<CountUp className='counter' end={number} text={text} />
			<span>{title}</span>
		</div>
	)
}

export default Count