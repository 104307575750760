import React, { useEffect, CSSProperties, useContext, useState } from 'react'
import Home from './routes/Home'
import Project from './routes/Project'
import Contact from './routes/Contact'
import About from './routes/About'
import Navbar from './components/Navbar'
import { Route, Router, Routes, useLocation } from 'react-router-dom'
import AnimatedRoutes from './Animate/AnimatedRoutes'
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
const App = () => {
  const [loading, setloading] = useState(false)
  useEffect(() => {
    setloading(true)
    setTimeout(() => {
      setloading(false)
    }, 5000)
  }, [])
  const mystyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
  };
  return (

    <>
      <div className='App' >
        {loading ? (<ClimbingBoxLoader style={mystyle}
          size={30} color={"#edc218"} loading={loading}
        />) : (<AnimatedRoutes />)}

      </div>
    </>
  )
}

export default App