import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import logo from '../assets/myLogo.png'
function Navbar() {
    const [click, setClick] = useState(false);

    const navMenu = document.querySelector('.nav_menu')

    const toogleMenu = () => {
        if (click) {
            return navMenu
        }
    }
    const [color, setColor] = useState(false);
    const changeColor = () => {
        if (window.scrollY >= 100) {
            setColor(true);
        } else {
            setColor(false);
        }
    };

    window.addEventListener("scroll", changeColor);
    return (
        <>
            <div className={color ? "header header_bg" : "header"}>
                <Link to='/'>
                    <img src={logo} alt="logo" width={250} height={150} />
                </Link>
                <ul className={click ? "nav_menu active" : "nav_menu"}>
                    <li>
                        <Link to='/'>Home</Link>
                    </li>
                    <li>
                        <Link to='/about'>About</Link>
                    </li>
                    <li>
                        <Link to='/project'>Projects</Link>
                    </li>
                    <li>
                        <Link to='/contact'>Contact</Link>
                    </li>

                </ul>
                <div className='hamburger' onClick={() => toogleMenu(setClick(!click))}>
                    {click ? (<FaTimes size={20} style={{ color: "#fff" }} />) : (<FaBars size={20} style={{ color: "#fff" }} />)}

                </div>
            </div>
        </>
    )
}


export default Navbar
