const LeftExperienceData = [
	{
		Cname: "FDC Ltd. -Andheri- ",
		Ctitle: "Web Developer",
		Cdetails: "I worked on Asset data entry form which was connected to company database I used PHP and MYSQL for this projectI am adept at applying data structures and algorithms to optimize application performe and ensure efficient problem- solving."
	},
	{
		Cname: "CodersCave - ",
		Ctitle: " Web Developer Intern",
		Cdetails: "I had done 4 project for that company as it was a virtual internship got from linkedIn.I excel in solving complex problems, optimizing code,and delivering innovative solutions.My portfolio showcases arange of projects that highlight my technical skills and commitment to quality.Thank you for visiting, and I hope you enjoy exploring my work!."
	},

]
const RightExperienceData = [{
	Cname: "Oasis Infobyte -",
	Ctitle: "Web Developer Intern",
	Cdetails: "I had done 4 project for that company as it was a virtualinternship got from linkedIn.One of the projects I am particularly proud of involved creating a fully functional e- commerce website, which allowed me to implement front - end design principles and back - end functionalities"
},
{
	Cname: "Asynk ",
	Ctitle: "React Developer",
	Cdetails: "I am working in this company as React Developer. My hands-on experience includes developing user-friendly websites and applications, collaborating with peers on coding projects, and utilizing tools like Git for version control."
}]
export { LeftExperienceData, RightExperienceData };