import React from 'react'
import "./Hero.css"
import Bgimg from "../assets/bg.jpg"
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Typewriter } from 'react-simple-typewriter'
const Heroimg = () => {

    const handleType = (count: number) => {
        // access word count number
        console.log(count)
    }
    const handleDone = () => {
        console.log(`Done after 5 loops!`)
    }
    return (
        <>
            <motion.div
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                exit={{ width: window.innerWidth, transition: { duration: 0.2 } }}
                className="hero">
                <div className='mask'>
                    <img src={Bgimg} alt="bgimg" className="intro_img" />
                </div>
                <div className="content">
                    <motion.p
                        initial={{ x: -1000 }}
                        animate={{ x: 0 }}
                        transition={{
                            duration: 0.2,
                            delay: 0
                        }}


                    >Hi, I'm</motion.p>
                    <motion.h1
                        initial={{ x: -1000 }}
                        animate={{ x: 0 }}
                        transition={{
                            duration: 0.2,
                            delay: 0
                        }}>
                        <Typewriter
                            words={['React Developer', 'Web Developer', 'Firebase Expert']}
                            loop={5}
                            cursor
                            cursorStyle='|'
                            typeSpeed={70}
                            deleteSpeed={50}
                            delaySpeed={1000}
                            onLoopDone={handleDone}
                            onType={handleType}
                        />


                    </motion.h1>
                    <motion.div
                        initial={{ y: -1000 }}
                        animate={{ y: 0 }}
                        transition={{
                            duration: 0.2,
                            delay: 0
                        }}
                    >
                        <Link to="/project" className='btn'>Project</Link>
                        <Link to="/contact" className='btn-light'>Contact</Link>
                    </motion.div>
                </div>
            </motion.div>
        </>
    )
}

export default Heroimg
