import React from 'react'
import "../components/Count/count.css"
import Count from './Count/Count'
// import Count from './Count/Count'

import { motion, useScroll } from 'framer-motion'
const Counter = ({ title, number, text }) => {
	return (
		<>
			<div className='counting'>
				<h2 className='heading'>Achivements</h2>
				<motion.div
					initial={{ opacity: 0, scale: 0 }}
					whileInView={{ opacity: 1, scale: 1 }}
					transition={{ duration: 0.6, type: "spring" }}
					viewport={{ once: true }}
					className="count"
				>
					<motion.div initial={{ opacity: 0, scale: 0 }}
						whileInView={{ opacity: 1, scale: 1 }}
						transition={{ duration: 0.6, type: "spring" }}
						viewport={{ once: true }} className='count1'>
						<Count number={5} className="counter" />
						<p className='sub-text'>Internships</p>
					</motion.div>
					<motion.div
						initial={{ opacity: 0, scale: 0 }}
						whileInView={{ opacity: 1, scale: 1 }}
						transition={{ duration: 0.6, type: "spring" }}
						viewport={{ once: true }}
						className='count2'>
						<Count number={50} className="counter" />
						<p className='sub-text'>Projects</p>
					</motion.div>
					<motion.div
						initial={{ opacity: 0, scale: 0 }}
						whileInView={{ opacity: 1, scale: 1 }}
						transition={{ duration: 0.6, type: "spring" }}
						viewport={{ once: true }}
						className='count3'>
						<Count number={7} className="counter" />
						<p className='sub-text'>Experience</p>
					</motion.div>
				</motion.div>
			</div>
		</>
	)
}

export default Counter