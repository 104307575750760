import React, { useState } from 'react'
import './card.css'
import { NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'
const Workcard = (props) => {
    const [showCard, setShowCard] = useState(false)
    return (
        <div>
            <motion.div className="project-card"
                whileHover={{ scale: 1 }} transition={{
                    layout: {
                        duration: 1,
                        type: 'spring'
                    }
                }}
                layout
                onClick={() => setShowCard(!showCard)}




            >
                <motion.img src={props.imgsrc} alt="" />
                <motion.h2 initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="project-title text-black">{props.title}</motion.h2>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='pro-details'>
                    <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }}>{props.text}</motion.p>
                </motion.div>
                {showCard && (
                    <Card />)
                }
            </motion.div>
        </div>

    )
}



function Card(props) {
    return (
        <div>

            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="pro-btns">
                <NavLink to={props.view} className="btn">View</NavLink>
                <NavLink to={props.source} className="btn">Source</NavLink>
            </motion.div>
        </div>
    )
}




export default Workcard
