import React from 'react'
import Marquee from "react-fast-marquee"
import "./marquee.css"
import asynk from "../../assets/logo_white.png"
import fdc from "../../assets/FDCLogo.png"
import codercave from "../../assets/CodersCaveLogo.png"
// import prodLogo from "../../assets/ProdigyLogo.png"
import { motion } from 'framer-motion'
const HomeMarquee = () => {
	return (
		<>
			<div className="marquee_container">
				<motion.h1 initial={{ opacity: 0, scale: 0 }}
					whileInView={{ opacity: 1, scale: 1 }}
					transition={{ duration: 0.6, type: "spring" }}
					viewport={{ once: true }}
					className='marquee_heading'>Internships Completed </motion.h1>
				<motion.div className="marquee_content">
					<Marquee
						pauseOnClick={true}
						pauseOnHover={true}
						speed={100}
					>
						<div className='slide'>
							<img src={asynk} alt="" />
						</div>
						<div className='slide'>
							<img src={fdc} alt="" />
						</div>
						{/* <div className='slide'>
							<img src={prodLogo} alt="" />
						</div> */}
					</Marquee>
				</motion.div>
			</div>
		</>
	)
}

export default HomeMarquee