import React from 'react'
import '../tab/tab.css'
import { LeftExperienceData, RightExperienceData } from './data'
import { motion } from 'framer-motion'
function Experience() {
    return (
        <>
            <div className="experience">
                <div className="exp_title">
                    <motion.h2 initial={{ opacity: 0, scale: 0 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.6, type: "spring" }}
                        viewport={{ once: true }}
                    >Experience</motion.h2>
                </div>
                <div className="exp_content">
                    <div className="left">
                        {
                            LeftExperienceData.map((item, index) => (
                                <motion.div initial={{ opacity: 0, scale: 0 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.6, type: "spring" }}
                                    viewport={{ once: true }} key={index} className='content'>
                                    <h1 className=''>{item.Cname}<span>{item.Ctitle}</span></h1>
                                    <p>{item.Cdetails}</p>
                                </motion.div>
                            ))
                        }
                    </div>
                    <div className="right">
                        {
                            RightExperienceData.map((item, index) => (
                                <motion.div initial={{ opacity: 0, scale: 0 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.6, type: "spring" }}
                                    viewport={{ once: true }} key={index} className='content'>
                                    <h1 className=''>{item.Cname}<span>{item.Ctitle}</span></h1>
                                    <p>{item.Cdetails}</p>
                                </motion.div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Experience
